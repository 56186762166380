@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
    font-family: 'Lato';
}

.fa-btn {
    margin-right: 6px;
}

.input-group-file {
    span.btn-file {
        position: relative;
        overflow: hidden;
    }

    input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        background: white;
        cursor: inherit;
        display: block;
    }

    input[readonly] {
        background-color: white !important;
        cursor: text !important;
    }
}

.main {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 80px;
}

.dataTable {
    width: 100% !important;
    .btn {
        padding: 4px 12px;
    }
    img {
        max-width: 100%;
    }
}

select {
    font-size: 14px!important;
}

.information {
    font-size: 17px;
    li {
        line-height: 28px;
    }
    li.no {
        list-style-type: none;
    }
    .highlight {
        width: 100%;
        display: block;
        margin-top: 8px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    img {
        max-width: 100%;
        border: 1px solid grey;
    }
}

.btn-breadcrumb {
    .btn {
        padding: 6px 12px 6px 24px;
        &:first-child {
            padding: 6px 6px 6px 10px;
        }
        &:last-child {
            padding: 6px 18px 6px 24px;
        }
        &:not(:last-child):after {
            content: " ";
            display: block;
            width: 0;
            height: 0;
            border-top: 17px solid transparent;
            border-bottom: 17px solid transparent;
            border-left: 10px solid white;
            position: absolute;
            top: 50%;
            margin-top: -17px;
            left: 100%;
            z-index: 3;
        }
        &:not(:last-child):before {
            content: " ";
            display: block;
            width: 0;
            height: 0;
            border-top: 17px solid transparent;
            border-bottom: 17px solid transparent;
            border-left: 10px solid rgb(173, 173, 173);
            position: absolute;
            top: 50%;
            margin-top: -17px;
            margin-left: 1px;
            left: 100%;
            z-index: 3;
        }

        &.btn-default:not(:last-child) {
            &:after {
                border-left: 10px solid #fff;
            }
            &:before {
                border-left: 10px solid #ccc;
            }
            &:hover:after, &:focus:after, &:active:after {
                border-left: 10px solid #e6e6e6;
            }
            &:hover:before, &:focus:before &:active:before {
                border-left: 10px solid #adadad;
            }
        }

        &.btn-primary:not(:last-child) {
            &:after {
                border-left: 10px solid #337ab7;
            }
            &:before {
                border-left: 10px solid #357ebd;
            }
            &:hover:after, &:focus:after, &:active:after {
                border-left: 10px solid #286090;
            }
            &:hover:before, &:focus:before &:active:before {
                border-left: 10px solid #285e8e;
            }
        }

        &:active, &:focus {
            z-index: initial !important;
        }
    }
}

#configurator-steps {
    a {
        border-bottom: 1px;
        &:first-child, &:last-child {
            @extend .bottom-flat;
        }
    }
}

.box {
    margin-bottom: 10px;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
    border-radius: 4px;
}

.padding {
    padding: 9px 14px;
}

.full {
    width: 100%;
}

.top-flat {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.bottom-flat {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.full.box.top-flat {
    min-height: 100px;
}

#modal-set-steps-sortable {
    padding-left: 0;
    li {
        @extend .box;
        @extend .padding;
        list-style-type: none;
    }
    .col-xs-1 {
        margin-top: 7px;
    }
}

canvas {
    border: 1px solid grey;
    width: 100%;
    cursor: pointer;
}

#elements {
    ul {
        padding: 0;
        margin-bottom: 0;
        li {
            list-style-type: none;
            display: block;
            border-radius: 0;
            border-left: 0;
            border-top: 0;
            border-right: 0;
            &.active {
                border-color: #ccc !important;
                background-color: #888!important;
                color: white;
            }
            &:hover {
                border-color: #ccc !important;
            }
            .btn:hover {
                border-color: #ccc !important;
            }
        }
    }
    .col-xs-1 {
        i {
            margin-top: 8px;
        }
    }
}

#element-settings {
    img {
        max-width: 100%;
        border: 1px solid grey;
        margin-top: 15px;
        &:not([src]) {
            display: none;
        }
    }
}

.element-settings-video {
    .col-xs-11 {
        padding-right: 0;
    }
    .col-xs-1 {
        padding-left: 4px;
    }
    i {
        font-size: 21px;
        cursor: pointer;
    }
}

pre {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    background-color: white !important;
    margin-bottom: -30px !important;
}

code.hljs {
    margin-top: -14px;
}

.dropzone {
    width: 100%;
    height: 80px;
    cursor: pointer;
    opacity: 0;
}

.dropzone + img {
    border: none !important;
}

.upload {
    text-align: center;
    margin-top: 15px;
    outline: 2px dashed grey;
    &.is-dragover {
        outline-color: green;
    }
    &.is-finished {
        background-color: green;
    }
    input {
        z-index: 5;
        cursor: pointer;
    }
    p {
        position: absolute;
        z-index: 1;
        top: 60px;
        font-size: 25px;
        font-weight: bold;
        color: grey;
        pointer-events: none;
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    &.is-finished p {
        color: white;
    }
}

@media (min-width: 992px) {
    .upload p {
        margin: 17px 0 0;
    }
}

canvas:not(#main-canvas) {
    height: 100%;
}

.toast {
    height: auto;
    margin-top: -17px;
    margin-bottom: 14px;
    background-color: #383838;
    color: #F0F0F0;
    font-size: 15px;
    padding: 10px;
    text-align: center;
    border-radius: 2px;
    box-shadow: 0 0 24px -1px rgba(56, 56, 56, 1);
}

#part-right .col-xs-12>button {
    padding-left: 5px;
    padding-right: 5px;
}